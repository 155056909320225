<template>
    <div>
        <ts-page-title
            :title="$t('loanType.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('loanType.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper
                class="tw-flex tw-items-center tw-justify-between"
            >
                <Button type="info" ghost @click="addNew">{{
                    $t("addNew")
                }}</Button>
                <div class="tw-justify-end tw-space-x-2">
                    <Input
                        search
                        v-model="search"
                        :placeholder="$t('loanType.search')"
                        :style="width"
                        @on-focus="() => (width = 'width: 500px')"
                        @on-blur="() => (width = 'width: 300px')"
                    />
                </div>
            </ts-panel-wrapper>
            <Table
                :columns="columns"
                :data="resources"
                size="small"
                :loading="loading"
                max-height="700"
                stripe
            >
                <template slot-scope="{ row }" slot="default_interest_rate">
                    {{ row.default_interest_rate ? row.default_interest_rate : 0 }}%
                </template>
                <template slot-scope="{ row }" slot="admin_fee_rate">
                    {{ row.admin_fee_rate }}%
                </template>
                <template slot-scope="{ row }" slot="insurance_fee_rate">
                    {{ row.insurance_fee_rate }}%
                </template>
                <template slot-scope="{ row }" slot="monthly_fee_rate">
                    {{ row.monthly_fee_rate }}%
                </template>
                <template slot-scope="{ row }" slot="rescue_fee">
                    {{ row.rescue_fee }}
                </template>
                <template slot-scope="{ row }" slot="daily">
                    <span
                        v-if="row.daily"
                        class="badge bg-success tw-mr-1 tw-capitalize"
                    >
                    Yes
                    </span>
                    <span
                        v-else
                        class="badge bg-secondary tw-mr-1 tw-capitalize"
                    >
                    No
                    </span>
                </template>
                <template slot-scope="{ row }" slot="weekly">
                    <span
                        v-if="row.weekly"
                        class="badge bg-success tw-mr-1 tw-capitalize"
                    >
                    Yes
                    </span>
                    <span
                        v-else
                        class="badge bg-secondary tw-mr-1 tw-capitalize"
                    >
                    No
                    </span>
                </template>
                <template slot-scope="{ row }" slot="monthly">
                   <span
                        v-if="row.monthly"
                        class="badge bg-success tw-mr-1 tw-capitalize"
                    >
                    Yes
                    </span>
                    <span
                        v-else
                        class="badge bg-secondary tw-mr-1 tw-capitalize"
                    >
                    No
                    </span>
                </template>
                <template slot-scope="{ row }" slot="is_skip_sunday">
                    <i-switch
                        v-tooltip="$t('loanType.isSkipSunday')"
                        size="small"
                        v-model="row.is_skip_sunday"
                        :loading="row._switching"
                        @on-change="onChangeIsSkipSunday(row)"
                        style="margin-right: 4px"
                    >
                    </i-switch>
                </template>
                <template slot-scope="{ row }" slot="is_active">
                    <Icon
                        v-if="row.is_active"
                        type="ios-checkmark-circle-outline text-success"
                        size="20"
                    />
                    <Icon
                        v-else
                        type="ios-close-circle-outline text-danger"
                        size="20"
                    />
                </template>
                <template slot-scope="{ row }" slot="action">
                    <i-switch
                        v-tooltip="$t('loanType.isActive')"
                        size="small"
                        v-model="row.is_active"
                        :loading="row._switching"
                        @on-change="onChangeIsActive(row)"
                        style="margin-right: 4px"
                    >
                    </i-switch>
                    <a
                        href="#"
                        class="text-primary ml-4"
                        @click.prevent="onEdit(row)"
                        v-tooltip="$t('edit')"
                    >
                        <Icon type="ios-create" size="20" />
                    </a>
                </template>
            </Table>
            <div class="tw-flex tw-items-center tw-justify-end tw-p-4">
                <ts-i-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-i-pagination>
            </div>
            <!-- form-action -->
            <Modal
                v-model="showForm"
                draggable
                sticky
                scrollable
                :mask="false"
                :footer-hide="true"
                :z-index="1022"
                :title="$t('loanType.pageTitle')"
                width="600"
            >
                <from-action
                    ref="form_action"
                    @cancel="clearEdit"
                    @fetchData="fetchData"
                />
            </Modal>
        </ts-panel>
    </div>
</template>

<script>
import { debounce } from "lodash";
import { mapState } from "vuex";
import FromAction from "./form";

export default {
    name: "loanTypeIndex",
    components: {
        FromAction
    },
    data() {
        return {
            loading: false,
            showForm: false,
            width: "width: 300px"
        };
    },
    computed: {
        ...mapState("creditOperation/loanType", ["resources", "pagination"]),
        search: {
            get() {
                return this.$store.state.creditOperation.loanType.search;
            },
            set(newValue) {
                this.$store.commit("creditOperation/loanType/SET_SEARCH", newValue);
                this.$store.commit("creditOperation/loanType/RESET_CURRENT_PAGE");
            }
        },
        columns() {
            return [
                {
                    title: this.$t("loanType.nameEn"),
                    key: "loan_type_en",
                    sortable: true,
                    minWidth: 250,
                },
                {
                    title: this.$t("loanType.nameKh"),
                    key: "loan_type_kh",
                    sortable: true,
                    minWidth: 250,
                },
                {
                    title: this.$t("loanType.interestRate"),
                    key: "default_interest_rate",
                    slot: "default_interest_rate",
                    minWidth: 120,
                    align: "right"
                },
                {
                    title: this.$t("loanType.adminFee"),
                    key: "admin_fee_rate",
                    slot: "admin_fee_rate",
                    width: 100,
                    align: "right"
                },
                {
                    title: this.$t("loanType.insuranceFee"),
                    key: "insurance_fee_rate",
                    slot: "insurance_fee_rate",
                    width: 120,
                    align: "right"
                },
                {
                    title: this.$t("loanType.monthlyFee"),
                    key: "monthly_fee_rate",
                    slot: "monthly_fee_rate",
                    width: 120,
                    align: "right"
                },
                {
                    title: this.$t("loanType.rescueFee"),
                    slot: "rescue_fee",
                    width: 120,
                    align: "right"
                },
                {
                    title: this.$t("loanType.daily"),
                    slot: "daily",
                    align: "center",
                    width: 120,
                    filters: [
                        {
                            label: "Yes",
                            value: true
                        },
                        {
                            label: "No",
                            value: false
                        }
                    ],
                    filterMultiple: false,
                    filterRemote(value) {
                        this.fetchData({
                            filter: {
                                daily: value
                            }
                        });
                    }
                },
                {
                    title: this.$t("loanType.weekly"),
                    slot: "weekly",
                    align: "center",
                    width: 120,
                    filters: [
                        {
                            label: "Yes",
                            value: true
                        },
                        {
                            label: "No",
                            value: false
                        }
                    ],
                    filterMultiple: false,
                    filterRemote(value) {
                        this.fetchData({
                            filter: {
                                weekly: value
                            }
                        });
                    }
                },
                {
                    title: this.$t("loanType.monthly"),
                    slot: "monthly",
                    align: "center",
                    width: 120,
                    filters: [
                        {
                            label: "Yes",
                            value: true
                        },
                        {
                            label: "No",
                            value: false
                        }
                    ],
                    filterMultiple: false,
                    filterRemote(value) {
                        this.fetchData({
                            filter: {
                                monthly: value
                            }
                        });
                    }
                },
                {
                    title: this.$t("loanType.isSkipSunday"),
                    slot: "is_skip_sunday",
                    align: "center",
                    width: 140,
                    filters: [
                        {
                            label: "Yes",
                            value: true
                        },
                        {
                            label: "No",
                            value: false
                        }
                    ],
                    filterMultiple: false,
                    filterRemote(value) {
                        this.fetchData({
                            filter: {
                                is_skip_sunday: value
                            }
                        });
                    }
                },
                {
                    title: this.$t("loanType.isActive"),
                    slot: "is_active",
                    align: "center",
                    width: 120,
                    filters: [
                        {
                            label: "Active",
                            value: true
                        },
                        {
                            label: "Inactive",
                            value: false
                        }
                    ],
                    filterMultiple: false,
                    filterRemote(value) {
                        this.fetchData({
                            filter: {
                                is_active: value
                            }
                        });
                    }
                },
                {
                    title: this.$t("loanType.defaultNumberOfCycle"),
                    key: "default_number_of_cycle",
                    align: "center",
                    width: 180,
                },
                {
                    title: this.$t("actions"),
                    key: "action",
                    slot: "action",
                    fixed: "right",
                    align: "center",
                    className: "tw-space-x-2",
                    width: 100
                }
            ];
        }
    },
    methods: {
        fetchData(attributes) {
            this.loading = true;
            this.$store
                .dispatch("creditOperation/loanType/fetch", { ...attributes })
                .then(() => {
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.notice({ type: "error", text: error.message });
                });
        },
        onEdit(record) {
            this.$store.commit("creditOperation/loanType/SET_EDIT_DATA", record);
            this.showForm = true;
            this.$refs.form_action.setEditData();
        },
        clearEdit() {
            this.showForm = false;
            this.$refs.form_action.clearInput();
            this.$store.commit("creditOperation/loanType/SET_EDIT_DATA", {});
        },
        addNew() {
            this.$store.commit("creditOperation/loanType/SET_EDIT_DATA", {});
            this.showForm = true;
            this.$refs.form_action.setEditData();
            this.$refs.form_action.clearInput();
        },
        async onChangeIsSkipSunday(record) {
            record._switching = true;
            this.$store
                .dispatch("creditOperation/loanType/changeIsSkipSunday", {
                    id: record.loan_type_id,
                    data: { is_skip_sunday: record.is_skip_sunday }
                })
                .then(response => {
                    this.fetchData();
                    record._switching = false;
                    this.notice({
                        type: "success",
                        text: response.message
                    });
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                    record._switching = false;
                });
        },
        async onChangeIsActive(record) {
            record._switching = true;
            this.$store
                .dispatch("creditOperation/loanType/changeIsActive", {
                    id: record.loan_type_id,
                    data: { is_active: record.is_active }
                })
                .then(response => {
                    this.fetchData();
                    record._switching = false;
                    this.notice({
                        type: "success",
                        text: response.message
                    });
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                    record._switching = false;
                });
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "LOAN PRODUCT",
                desc: not.text
            });
        }
    },
    watch: {
        search: debounce(function(search) {
            this.fetchData({ search });
        }, 500)
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit("creditOperation/loanType/RESET_STATE");
        next();
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchData();
        });
    }
};
</script>
