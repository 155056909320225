<template>
    <div>
        <div class="demo-spin-article">
            <div class="mb-3">
                <div class="col-md-12">
                    <label class="form-col-label control-label">{{
                        $t('loanType.nameKh')
                    }}</label>
                    <input
                        ref="loan_type_kh"
                        v-model="model.loan_type_kh"
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': errors.has('loan_type_kh') }"
                    />
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('loan_type_kh')"
                    >
                        {{ errors.first('loan_type_kh') }}
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <div class="col-md-12">
                    <label class="form-col-label control-label required">{{
                        $t('loanType.nameEn')
                    }}</label>
                    <input
                        v-model="model.loan_type_en"
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': errors.has('loan_type_en') }"
                    />
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('loan_type_en')"
                    >
                        {{ errors.first('loan_type_en') }}
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-12">
                    <label>{{ $t('loanType.interestRate') }}</label>
                    <InputNumber
                        :max="100"
                        :min="0"
                        v-model.number="model.default_interest_rate"
                        :formatter="value => `${value}%`"
                        :parser="value => value.replace('%', '')"
                        :placeholder="$t('loanType.rate')"
                        :class="{
                            'ivu-form-item-error': errors.has(
                                'default_interest_rate'
                            )
                        }"
                        style="width: 100%"
                    ></InputNumber>
                    <div
                        class="text-danger"
                        v-if="errors.has('default_interest_rate')"
                    >
                        {{ errors.first('default_interest_rate') }}
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-4">
                    <label>{{ $t('loanType.adminFee') }}</label>
                    <InputNumber
                        :max="100"
                        :min="0"
                        v-model.number="model.admin_fee_rate"
                        :formatter="value => `${value}%`"
                        :parser="value => value.replace('%', '')"
                        :placeholder="$t('loanType.rate')"
                        :class="{
                            'ivu-form-item-error': errors.has('admin_fee_rate')
                        }"
                        style="width: 131px"
                    ></InputNumber>
                    <div
                        class="text-danger"
                        v-if="errors.has('admin_fee_rate')"
                    >
                        {{ errors.first('admin_fee_rate') }}
                    </div>
                </div>
                <div class="col-md-4">
                    <label>{{ $t('loanType.insuranceFee') }}</label>
                    <InputNumber
                        :max="100"
                        :min="0"
                        v-model.number="model.insurance_fee_rate"
                        :formatter="value => `${value}%`"
                        :parser="value => value.replace('%', '')"
                        :placeholder="$t('loanType.rate')"
                        :class="{
                            'ivu-form-item-error': errors.has(
                                'insurance_fee_rate'
                            )
                        }"
                        style="width: 131px"
                    ></InputNumber>
                    <div
                        class="text-danger"
                        v-if="errors.has('insurance_fee_rate')"
                    >
                        {{ errors.first('insurance_fee_rate') }}
                    </div>
                </div>
                <div class="col-md-4">
                    <label>{{ $t('loanType.monthlyFee') }}</label>
                    <InputNumber
                        :max="100"
                        :min="0"
                        v-model.number="model.monthly_fee_rate"
                        :formatter="value => `${value}%`"
                        :parser="value => value.replace('%', '')"
                        :placeholder="$t('loanType.rate')"
                        :class="{
                            'ivu-form-item-error': errors.has(
                                'monthly_fee_rate'
                            )
                        }"
                        style="width: 131px"
                    ></InputNumber>
                    <div
                        class="text-danger"
                        v-if="errors.has('monthly_fee_rate')"
                    >
                        {{ errors.first('monthly_fee_rate') }}
                    </div>
                </div>
            </div>
            <div
                class="mt-3 tw-p-2 tw-border tw-border-gray-200 tw-rounded-lg tw-flex tw-justify-between"
            >
                <ts-checkbox v-model="model.daily">{{
                    $t('loanType.daily')
                }}</ts-checkbox>
                <ts-checkbox v-model="model.weekly">{{
                    $t('loanType.weekly')
                }}</ts-checkbox>
                <ts-checkbox v-model="model.monthly">{{
                    $t('loanType.monthly')
                }}</ts-checkbox>
            </div>
            <div class="mb-3 mt-2">
                <div class="col-md-12">
                    <label class="form-col-label control-label">{{
                        $t('loanType.currency')
                    }}</label>
                    <RadioGroup
                        v-model.number="model.default_currency_id"
                        style="width: 100%"
                    >
                        <Radio
                            class="mt-1"
                            :label="c.currency_id"
                            border
                            v-for="(c, index) in currencies"
                            :key="index"
                        >
                            {{ c.currency_code }}
                        </Radio>
                    </RadioGroup>
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('default_currency_id')"
                    >
                        {{ errors.first('default_currency_id') }}
                    </div>
                </div>
            </div>
            <div class="mb-3">
                <div class="col-md-12">
                    <label class="form-col-label control-label">{{
                        $t('loanType.repaymentMethod')
                    }}</label>
                    <select
                        v-model.number="model.default_repayment_method_id"
                        class="form-select"
                        :class="{
                            'is-invalid': errors.has(
                                'default_repayment_method_id'
                            )
                        }"
                    >
                        <option
                            :value="null"
                            disabled
                            selected
                            style="display: none;"
                            >{{ $t('select') }}</option
                        >
                        <option
                            v-for="(option, index) in repaymentMethods"
                            :value="option.repayment_method_id"
                            :key="index"
                        >
                            {{ option.method_name_en }}
                            {{
                                option.method_name_kh
                                    ? '| ' + option.method_name_kh
                                    : ''
                            }}
                        </option>
                    </select>
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('default_number_of_cycle')"
                    >
                        {{ errors.first('default_number_of_cycle') }}
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-6">
                    <label class="form-col-label control-label">{{
                        $t('loanType.defaultNumberOfCycle')
                    }}</label>
                    <input
                        v-model.number="model.default_number_of_cycle"
                        type="number"
                        class="form-control"
                        :class="{
                            'is-invalid': errors.has('default_number_of_cycle')
                        }"
                    />
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('default_number_of_cycle')"
                    >
                        {{ errors.first('default_number_of_cycle') }}
                    </div>
                </div>
                <div class="col-md-6">
                    <label class="form-col-label control-label">{{
                        $t('loanType.rescueFee')
                    }}</label>
                    <input
                        v-model.number="model.rescue_fee"
                        type="number"
                        class="form-control"
                        :class="{
                            'is-invalid': errors.has('rescue_fee')
                        }"
                    />
                    <div
                        class="invalid-feedback"
                        v-if="errors.has('rescue_fee')"
                    >
                        {{ errors.first('rescue_fee') }}
                    </div>
                </div>
            </div>
            <div class="mt-3">
                <ts-checkbox v-model="model.is_skip_sunday">{{
                    $t('loanType.isSkipSunday')
                }}</ts-checkbox>
            </div>
            <div class="mt-3">
                <ts-checkbox v-model="model.is_active">{{
                    $t('loanType.isActive')
                }}</ts-checkbox>
            </div>
            <div slot="footer">
                <div class="tw-flex tw-justify-end tw-space-x-2 tw-sh">
                    <ts-button
                        @click.prevent="() => $emit('cancel')"
                        class="btn-gray"
                    >
                        {{ $t('cancel') }}</ts-button
                    >
                    <ts-button
                        color="primary"
                        v-if="!isUpdate"
                        outline
                        :waiting="waiting_new"
                        :disabled="waiting"
                        @click.prevent="onSaveAddNew"
                    >
                        {{ $t('saveAddNew') }}</ts-button
                    >
                    <ts-button
                        color="primary"
                        v-if="!isUpdate"
                        :waiting="waiting"
                        :disabled="waiting_new"
                        @click.prevent="onSave"
                    >
                        {{ $t('save') }}</ts-button
                    >
                    <ts-button
                        v-if="isUpdate"
                        color="primary"
                        @click.prevent="onUpdate"
                        :waiting="waiting"
                    >
                        {{ $t('update') }}</ts-button
                    >
                </div>
            </div>
        </div>
        <Spin size="large" fix v-if="loading"> </Spin>
    </div>
</template>

<script>
import { Errors } from 'form-backend-validation'
import { mapActions, mapState } from 'vuex'
import { isEmpty } from 'lodash'

export default {
    name: 'loanTypeForm',
    data () {
        return {
            errors: new Errors(),
            loading: false,
            waiting: false,
            waiting_new: false,
            model: {
                loan_type_kh: null,
                loan_type_en: null,
                daily: false,
                weekly: false,
                monthly: true,
                default_interest_rate: null,
                admin_fee_rate: null,
                insurance_fee_rate: null,
                monthly_fee_rate: null,
                default_repayment_method_id: null,
                default_currency_id: null,
                default_number_of_cycle: null,
                rescue_fee: null,
                is_skip_sunday: false,
                is_active: true
            }
        }
    },
    created () {
        this.fetchResource()
    },
    computed: {
        ...mapState('creditOperation/loanType', [
            'edit_data',
            'currencies',
            'repaymentMethods'
        ]),
        isUpdate () {
            return !isEmpty(this.edit_data)
        }
    },
    methods: {
        ...mapActions('creditOperation/loanType', [
            'getCurrency',
            'getRepaymentMethod'
        ]),
        async fetchResource () {
            this.loading = true
            await this.getCurrency().then(() => {
                this.model.default_currency_id = this.$store.state.authUser.sys_setting[0].default_currency_id
            })
            await this.getRepaymentMethod()
            this.loading = false
        },
        onSaveAddNew () {
            this.errors = new Errors()
            this.waiting_new = true
            this.$store
                .dispatch('creditOperation/loanType/store', this.model)
                .then(response => {
                    this.notice({ type: 'success', text: response.message })
                    this.clearInput()
                    this.$emit('fetchData')
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting_new = false
                })
        },
        onSave () {
            this.errors = new Errors()
            this.waiting = true
            this.$store
                .dispatch('creditOperation/loanType/store', this.model)
                .then(response => {
                    this.notice({ type: 'success', text: response.message })
                    this.$emit('cancel')
                    this.$emit('fetchData')
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        onUpdate () {
            this.waiting = true
            this.$store
                .dispatch('creditOperation/loanType/update', {
                    id: this.edit_data.loan_type_id,
                    data: this.model
                })
                .then(response => {
                    this.notice({ type: 'success', text: response.message })
                    this.$emit('cancel')
                    this.$emit('fetchData')
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        setEditData () {
            this.$nextTick(function () {
                this.$refs.loan_type_kh.focus()
            })
            if (!isEmpty(this.edit_data)) {
                this.model.loan_type_kh = this.edit_data.loan_type_kh
                this.model.loan_type_en = this.edit_data.loan_type_en
                this.model.daily = this.edit_data.daily
                this.model.weekly = this.edit_data.weekly
                this.model.monthly = this.edit_data.monthly
                this.model.is_active = this.edit_data.is_active
                this.model.default_interest_rate = this.edit_data.default_interest_rate
                this.model.default_repayment_method_id = this.edit_data.default_repayment_method_id
                this.model.default_currency_id = this.edit_data.default_currency_id
                this.model.admin_fee_rate = this.edit_data.admin_fee_rate
                this.model.insurance_fee_rate = this.edit_data.insurance_fee_rate
                this.model.monthly_fee_rate = this.edit_data.monthly_fee_rate
                this.model.default_number_of_cycle = this.edit_data.default_number_of_cycle
                this.model.rescue_fee = this.edit_data.rescue_fee
                this.model.is_skip_sunday = this.edit_data.is_skip_sunday
            }
        },
        clearInput () {
            this.model.loan_type_kh = null
            this.model.loan_type_en = null
            this.model.default_interest_rate = null
            this.model.default_repayment_method_id = null
            this.model.default_currency_id = null
            this.model.admin_fee_rate = null
            this.model.insurance_fee_rate = null
            this.model.monthly_fee_rate = null
            this.model.default_number_of_cycle = null
            this.model.rescue_fee = null
            this.model.daily = false
            this.model.weekly = false
            this.model.monthly = true
            this.model.is_skip_sunday = false
            this.model.is_active = true
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'LAON PRODUCT',
                desc: not.text
            })
        }
    }
}
</script>
